<template>
  <div class="dashboard">
    <div class="dashboard__container">
      <div class="dashboard__container--header mb-2">
        <h1 class="pa-0 pb-3">Orders
         <!--  <span> - {{requiredCount.totalRequiredCount}}/</span>
          <span class="success">{{totalAssignmentsCount.totalAssignmentsCount}}</span> -->
        </h1>
        <span>
          <button class="btn ml-3" v-bind:class="{ 'btn__dark': showJobs, 'btn__outlined': !showJobs }" @click="showJobs = true">Jobs</button>
          <button class="btn ml-3" v-bind:class="{ 'btn__dark': !showJobs, 'btn__outlined': showJobs }" @click="showJobs = false">Gigs</button>
          <router-link :to="{name: 'addevent'}" class="color--text">
            <button class="btn btn__outlined ml-3">
              New Order
              <i class="fa-regular fa-plus ml-2"></i>
            </button>
          </router-link>
        </span>
        
      </div>
      <!-- <button class="btn btn__small mr-3" @click="updateOrders1()">Applicant Count</button>
      <button class="btn btn__small mr-3" @click="updateOrders2()">Required Count</button>
      <button class="btn btn__small mr-3" @click="updateOrders3()">Assignment Count</button> -->
      <div class="dashboard__container--body">
        <div>
          <button class="btn btn__small mr-3 mb-3" v-bind:class="{ 'btn__dark': showOpen, 'btn__outlined btn__small': !showOpen }" @click="showOpenOrders()">Open Orders</button>

          <button class="btn btn__small mr-3 mb-3" v-bind:class="{ 'btn__dark': showHeld, 'btn__outlined btn__small': !showHeld }" @click="showHeldOrders()">Held Orders</button>

          <button class="btn btn__small mr-3 mb-3" v-bind:class="{ 'btn__dark': showClosed, 'btn__outlined btn__small': !showClosed }" @click="showClosedOrders()">Closed Orders</button>
        </div>
      </div>
      <div class="dashboard__container--body">
        <Loader v-if="!events || events.length == 0" />
        <OrdersTable :userProfile="userProfile" :jobs="openJobs" :isJob="true" v-if="showOpen && showJobs" />
        <OrdersTable :userProfile="userProfile" :jobs="heldJobs" :isJob="true" v-if="showHeld && showJobs" />
        <OrdersTable :userProfile="userProfile" :jobs="closedJobs" :isJob="true" v-if="showClosed && showJobs" />
        <OrdersTable :userProfile="userProfile" :jobs="openGigs" :isJob="false" v-if="showOpen && !showJobs" />
        <OrdersTable :userProfile="userProfile" :jobs="heldGigs" :isJob="false" v-if="showHeld && !showJobs" />
        <OrdersTable :userProfile="userProfile" :jobs="closedGigs" :isJob="false" v-if="showClosed && !showJobs" /> 
      </div>
    </div>
  </div>
</template>


<script>
import * as moment from 'moment'
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import OrdersTable from '@/components/OrdersTable.vue'
import router from '@/router'
const fb = require('../../firebaseConfig.js')

export default {
  name: 'events',
  data: () => ({
    showOpen: true,
    showHeld: false,
    showClosed: false,
    showAllGroups: true,
    showMyGroups: false,
    showJobs: true,
  }),
  created () {
    this.$store.dispatch("getEvents")
    this.$store.dispatch("getOrderStats")
  },
  computed: {
    ...mapState(['currentUser', 'events', 'eventsTotal', 'userProfile', 'totalAssignmentsCount', 'requiredCount']),
    jobs() {
      if (this.events || this.events.length >= 1) {
        return this.events.filter(event => {
          return event.shiftType == 'Job'
        })
      }
    },
    gigs() {
      if (this.events || this.events.length >= 1) {
        return this.events.filter(event => {
          return event.shiftType == 'Gig'
        })
      }
    },
    openJobs() {
      if (this.showOpen && this.showJobs) {
        return this.jobs.filter(event => {
          return (!event.status || event.status == 'Open')
        })
      }
    },
    closedJobs() {
      if (this.showClosed && this.showJobs) {
        return this.jobs.filter(event => {
          return (event.status == 'Closed')
        })
      }
    },
    heldJobs() {
      if (this.showHeld && this.showJobs) {
        return this.jobs.filter(event => {
          return (event.status == 'On-Hold')
        })
      }
    },
    openGigs() {
      if (this.showOpen && !this.showJobs) {
        return this.gigs.filter(event => {
          return (!event.status || event.status == 'Open')
        })
      }
    },
    closedGigs() {
      if (this.showClosed && !this.showJobs) {
        return this.gigs.filter(event => {
          return (event.status == 'Closed')
        })
      }
    },
    heldGigs() {
      if (this.showHeld && !this.showJobs) {
        return this.gigs.filter(event => {
          return (event.status == 'On-Hold')
        })
      }
    }
  },
  components: {
    Loader,
    OrdersTable
  },
  methods: {
    // isWatchList(row) {
    //   if (this.userProfile && this.userProfile.orderWatchList && this.userProfile.orderWatchList.includes(row.id)) {
    //     return true
    //   } else {
    //     return false
    //   }
    // },
    showOpenOrders() {
      this.showOpen = true
      this.showClosed = false
      this.showHeld = false
    },
    showClosedOrders() {
      this.showOpen = false
      this.showClosed = true
      this.showHeld = false
    },
    showHeldOrders() {
      this.showOpen = false
      this.showClosed = false
      this.showHeld = true
    },
    // onRowClick(params) {
    //   let url = `/orders/` + params.row.id
    //   console.log(url)
    //   router.push(url)
    // },
    // formatDate(q) {
    //   if(q) {
    //     const postedDate = new Date(q.seconds) * 1000;
    //     return moment(postedDate).format('MMMM Do YYYY, hh:mm a')
    //   } else {
    //     return null
    //   }
    // },
    // async addWatch(row) {
    //   await this.$store.dispatch('watchOrder', row.id)
    // },
    // async removeWatch(row) {
    //   await this.$store.dispatch('unWatchOrder', row.id)
    // },
    // updateOrders1() {
    //   this.$store.dispatch('updateTheOrders1')
    // },
    // updateOrders2() {
    //   this.$store.dispatch('updateTheOrders2')
    // },
    // updateOrders3() {
    //   this.$store.dispatch('updateTheOrders3')
    // }
  },
  beforeDestroy () {
    this.columns = null
    delete this.columns
    this.showOpen = null
    delete this.showOpen
    this.showJobs = null
    delete this.showJobs
    this.showHeld = null
    delete this.showHeld
    this.showClosed = null
    delete this.showClosed
    this.showAllGroups = null
    delete this.showAllGroups
    this.showMyGroups = null
    delete this.showMyGroups
    delete this.formatDate
    delete this.onRowClick
    delete this.showHeldOrders
    delete this.showClosedOrders
    delete this.showOpenOrders
    delete this.openEvents
    delete this.closedEvents
    delete this.heldEvents
    this.$store.dispatch("clearEvents")
  },
}
</script>