<template>
  <div style="width:100%;background: white; overflow:auto;">
    <vue-good-table
            :columns="columns"
            :rows="jobs"
             styleClass="vgt-table condensed"
            :search-options="{
              enabled: true,
              placeholder: 'Search this table',
            }"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 50,
            }"
          >
          <template slot="table-column" slot-scope="props">
             <span v-if="props.column.label =='Pipeline'">

              <v-popover v-if="isJob">
                  <i class="fa-light fa-filter" style="font-size:1.5rem;"></i>
                  <template slot="popover">
                    Pipeine
                  </template>
                </v-popover>

                <v-popover v-if="!isJob">
                  <i class="fa-solid fa-list" style="font-size:1.5rem;"></i>
                  <template slot="popover">
                    Applications
                  </template>
                </v-popover>

                
             </span>
             <span v-else-if="props.column.label =='Hired'">
                <!-- <i class="fa-solid fa-list" style="font-size:1.5rem;"></i> -->
                <v-popover>

                  <i class="fa-solid fa-lock" style="font-size:1.5rem;"></i>
                  <template slot="popover">
                    Assignments
                  </template>
                </v-popover>
                
             </span>
             <span v-else-if="props.column.label =='Watch'">
                <!-- <div class="text-center">
                  <i class="fa-regular fa-star"></i>
                </div> -->
             </span>
             <span v-else-if="props.column.label =='Ask'">
              <v-popover>
                  <i class="fa-regular fa-bullseye" style="font-size:1.5rem;"></i>
                  <template slot="popover">
                    Staff Requested
                  </template>
                </v-popover>

                
             </span>

             


             
             <!-- <span v-else-if="props.column.field == 'watchlist'">
              <div style="width: 100%;" class="text-center">
                <i class="fa-regular fa-star"></i>
              </div>
            </span> -->
             <span v-else>
                {{props.column.label}}
             </span>
          </template>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'startDate'">
              <span v-if="props.row.startDate">{{props.row.startDate | moment("MM/DD/YYYY")}}</span>
            </span>
            <span v-else-if="props.column.field == 'visible'">
              <i class="fa-solid fa-check" v-if="props.row.visible" style="color:green;"></i>
            </span>
            <span v-else-if="props.column.field == 'title'">
              <router-link :to="`/orders/` + props.row.id">
                {{props.row.title}}
              </router-link>
            </span>
            
            
            <span v-else-if="props.column.field == 'requiredCount'">
              <span class="strong" v-if="props.row.requiredCount">
                {{props.row.requiredCount}}
              </span>
            </span>

            <span v-else-if="props.column.field == 'pipeline'">

              <span v-if="props.row.applicantCount && !isJob">
                {{props.row.applicantCount}}
              </span>


              <span class="strong" v-if="isJob && props.row.pipeline && props.row.pipeline.length">
                <span v-if="((props.row.pipeline && props.row.pipeline.length) && props.row.requiredCount && ((props.row.pipeline.length) > (props.row.requiredCount)))" class="success">
                  {{props.row.pipeline.length}}
                </span>
                <span v-else-if="((props.row.pipeline && props.row.pipeline.length) && props.row.requiredCount && ((props.row.pipeline.length) < (props.row.requiredCount)))" class="danger">
                  {{props.row.pipeline.length}}
                </span>
                <span v-else-if="((props.row.pipeline && props.row.pipeline.length) && props.row.requiredCount && ((props.row.pipeline.length) === (props.row.requiredCount)))" class="warning">
                  {{props.row.pipeline.length}}
                </span>

                <span v-else>
                  <span>
                    {{props.row.pipeline.length}}
                  </span>
                </span>
                
              </span>
            </span>

            <span v-else-if="props.column.field == 'placedCount'">
              <span v-if="props.row.placedCount" class="strong">

                <span v-if="(props.row.placedCount >= props.row.requiredCount)" class="success">
                  {{props.row.placedCount}}
                </span>

                <span v-else="(props.row.placedCount >= props.row.requiredCount)" class="danger">
                  {{props.row.placedCount}}
                </span>



              </span>
            </span>

            <!-- <span v-else-if="props.column.field == 'applicantCount'">
              <span v-if="props.row.applicantCount">
                {{props.row.applicantCount}}
              </span>
            </span> -->

            <span v-else-if="props.column.field == 'client'">
             <!--  <span v-if="props.row.venue.client && props.row.venue.client[0]">
                {{props.row.venue.client[0].title}}
              </span> -->
              <span v-if="props.row.client && props.row.client[0]">
                {{props.row.client[0].title}}
                <!-- <img v-if="props.row.subClient && props.row.subClient[0]" :src="props.row.subClient[0].logo" style="width:48px; margin-left:1rem;">


                <img v-if="props.row.amazon" src="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/amazon-logo-transparent.png?alt=media&token=927eb07d-74ad-4eaf-a9e6-9e2d1ed0dbb4" alt="" style="width:48px; margin-left:1rem;"> -->
              </span>
            </span>

            <span v-else-if="props.column.field == 'subClient'">
             <!--  <span v-if="props.row.venue.client && props.row.venue.client[0]">
                {{props.row.venue.client[0].title}}
              </span> -->
              <img v-if="props.row.subClient && props.row.subClient[0]" :src="props.row.subClient[0].logo" style="width:72px;">
            </span>

            <span v-else-if="props.column.field == 'watchlist'">
              <button v-if="!isWatchList(props.row)" class="btn btn__icon" @click="addWatch(props.row)"><i class="fa-regular fa-star"></i></button>
               <button v-if="isWatchList(props.row)" class="btn btn__icon" @click="removeWatch(props.row)"><i class="fa-solid fa-star warning"></i></button>
            </span>
            <span v-else-if="props.column.field == 'backgroundCheck'">
              <span v-if="props.row.backgroundCheck && props.row.backgroundCheck.title">
                {{props.row.backgroundCheck.title}}
              </span>
              <span v-else>{{props.row.backgroundCheck}}</span>
              
            </span>
            <span v-else-if="props.column.field == 'drugScreening'">
              <span v-if="props.row.drugScreening && props.row.drugScreening.title">
                {{props.row.drugScreening.title}}
              </span>
              <span v-else>{{props.row.drugCheck}}</span>
              
            </span>
            <span v-else-if="props.column.field == 'groups'">
              <span v-if="!props.row.groups || props.row.groups.length == 0">
                <i class="fa-solid fa-user-group ml-2 mr-2" style="opacity:50%;"></i>
              </span>
              <span v-if="props.row.groups && props.row.groups.length > 0">
                <v-popover>
                  <i class="fa-solid fa-user-group ml-2 mr-2 blueHue"></i>
                  <template slot="popover">
                    <span v-for="z in props.row.groups">{{z.title}} / </span>
                  </template>
                </v-popover>
              </span>
              <!-- <span v-if="props.row.groups && props.row.groups.length >= 1">
                <button class="chipDark mb-1" v-for="(item, index) in props.row.groups" :key="index">{{item.title}}</button>
              </span> -->
            </span>
            <span v-else>
              {{props.formattedRow[props.column.field]}}
            </span>
          </template>
        </vue-good-table>
  </div>
</template>

<script>
import * as moment from 'moment'
import { mapState } from 'vuex'
import router from '@/router'
const fb = require('../firebaseConfig.js')

export default {
  props: ['jobs', 'userProfile', 'isJob'],
  data: () => ({
    columns: [
      {
        label: 'Start Date',
        field: 'startDate',
         width: '72px',
        sortable: false,
      },
      {
        label: 'Watch',
        field: 'watchlist',
        // tdClass: 'text-center-all',
        tdClass: 'text-center',
        width: '32px',
        sortable: false,
      },
      {
        label: 'Ask',
        field: 'requiredCount',
        tdClass: 'text-center',
        width: '24px',
        sortable: false,
      },
      // {
      //   label: 'Queue',
      //   field: 'applicantCount',
      //   tdClass: 'text-center',
      //   width: '24px',
      //   sortable: false,
      // },
      {
        label: 'Pipeline',
        field: 'pipeline',
        tdClass: 'text-center',
        width: '24px',
        sortable: false,
      },
      {
        label: 'Hired',
        field: 'placedCount',
        tdClass: 'text-center',
        width: '24px',
        sortable: false,
      },
      {
        label: 'Title',
        field: 'title',
         width: '180px',
        sortable: false,
      },
      // {
      //   label: 'Type',
      //   field: 'shiftType',
      //   width: '60px',
      //   sortable: false,
      // },
      {
        label: 'Order #',
        field: 'jobNum',
        width: '60px'
      },
      {
        label: 'PO/Ref',
        field: 'refNum',
        width: '120px'
      },
      {
        label: 'Client',
        field: 'client',
         width: '160px',
         sortable: false,
      },
      {
        label: 'Sub',
        field: 'subClient',
         width: '80px',
         sortable: false,
      },
      {
        label: 'Background',
        field: 'backgroundCheck',
        width: '100px',
        sortable: false,
      },
      {
        label: 'Drug',
        field: 'drugScreening',
        width: '100px',
        sortable: false,
      },
      {
        label: 'Hours',
        field: 'glanceHours',
        width: '80px',
        width: '120px',
        sortable: false,
      },
      {
        label: 'Rate',
        field: 'glanceRate',
        width: '80px',
        sortable: false,
      },
      {
        label: 'Groups',
        field: 'groups',
        width: '60px',
        sortable: false,
      },
      {
        label: 'City',
        field: 'event_city',
         width: '80px'
      },
      {
        label: 'State',
        field: 'event_state',
         width: '60px'
      },
      {
        label: 'Jobsite',
        field: 'venue_title',
         width: '180px'
      },
      
      // {
      //   label: 'Published',
      //   field: 'visible',
      // },
    ],
  }),
  methods: {
    isWatchList(row) {
      if (this.userProfile && this.userProfile.orderWatchList && this.userProfile.orderWatchList.includes(row.id)) {
        return true
      } else {
        return false
      }
    },
    formatDate(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;
        return moment(postedDate).format('MMMM Do YYYY, hh:mm a')
      } else {
        return null
      }
    },
    addWatch(row) {
      this.$store.dispatch('watchOrder', row.id)
    },
    removeWatch(row) {
      this.$store.dispatch('unWatchOrder', row.id)
    },
  },
  beforeDestroy () {
    this.columns = null
    delete this.columns
  },
}
</script>